<template>
  <div
    :class="{ hidden: !$route.path.split('/')[1] || $route.path.split('/')[1] === 'districts' }"
    class="mobile-container"
  >
    <LoaderMobile :loading="loading" />
    <div v-if="!loading">
      <AppHeader />
      <MobileHeaderButtons />
      <NuxtPage />
      <MobileFooter />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import type { UnclaimedRecipeProps } from '~/types/crafting';
const loading = ref(true);
const store = useMainStore();
const { address } = useWeb3ModalAccount();
const { apiUrl } = useEnvs();

const { data: recipes, refresh: refreshRecipeList } = await useFetch<UnclaimedRecipeProps[]>(
  '/crafting/recipes/unclaimed',
  {
    baseURL: apiUrl,
    query: {
      userAddress: address
    },
    watch: [address, () => store.notificationUpdateVersion],
    key: 'unclaimed-recipes',
    immediate: !!address.value
  }
);

const unclaimedRecipes = computed(() => recipes.value);
provide('unclaimedRecipes', unclaimedRecipes);
provide('unclaimedRecipesRefresh', refreshRecipeList);

onMounted(() => {
  const currentMonth = new Date().getMonth();
  const isWinterMonth = currentMonth === 11 || currentMonth === 0;
  store.setIsNewYearTheme(isWinterMonth);

  setTimeout(() => {
    loading.value = false;
  }, 5000);

  window.addEventListener('beforeunload', () => {
    resetHashHistory();
  });
});
</script>

<style lang="scss" scoped>
.bottom-toolbar {
  bottom: 0;
  left: 0;
  position: absolute;
}
.mobile-container {
  height: 100dvh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.hidden {
  overflow: hidden;
}
</style>

<style>
.mobile-container {
  header {
    max-height: 80px;
  }

  .header-logo.new-year-logo {
    height: 50px;
  }

  .main-header-container {
    height: 80px;
  }
  .header-wrapper {
    padding-top: 15px;
    padding-bottom: 0px;
    border-bottom: none;
  }
}
</style>
